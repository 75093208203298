<template>
    <div class="blockchain-page" >
        <section-head title="sectionhead.title-blockchain" subtitle="sectionhead.subtitle-blockchain"/>
        <section-options style="margin-top: 100px" :icons="options.icons" :description="options.description"/>
        <section-contact-us :subtitle="''" />
        <section-time />
        <section-float title="floating.title-main-1">
            <element-float 
                v-for="(element, index) in options.floatingBlocks" 
                :key="index"
                :title="element.title"
                :icon="element.icon"
                :listFirst="element.listFirst"
                :listSecond="element.listSecond"
                :specialClass="`${index === options.floatingBlocks.length - 1 ? 'special-element' : ''}`"
                class="floating-el"
            />
        </section-float >
        <div class="blockchain-vertical-pointer">
            <svg class="blockchain-vertical-pointer-svg" width="46" height="2047" viewBox="0 0 46 2047" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="23" cy="23" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                <circle cx="23" cy="23" r="4" fill="#4071F4"/>
                <circle cx="23" cy="1017" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                <circle cx="23" cy="1017" r="4" fill="#4071F4"/>
                <circle cx="23" cy="1514.15" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                <circle cx="23" cy="1514.15" r="4" fill="#4071F4"/>
                <circle cx="23" cy="2049.81" r="22.5" fill="#0F1A38" stroke="#0F1A38"/>
                <circle cx="23" cy="2049.81" r="4" fill="#0F1A38"/>
                <circle cx="23" cy="520" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                <circle cx="23" cy="520" r="4" fill="#4071F4"/>
                <rect opacity="0.5" x="22" y="61" width="2" height="421" fill="#4071F4"/>
                <rect opacity="0.5" x="22" y="558" width="2" height="421" fill="#4071F4"/>
                <rect opacity="0.5" x="22" y="1055" width="2" height="421" fill="#4071F4"/>
                <rect opacity="0.5" x="22" y="1552.31" width="2" height="458.534" fill="#4071F4"/>
            </svg>
        </div>
        <section-footer paragraph="sectionfooter.paragraph-default"/>
    </div>
</template>

<script>
import sectionHead from '@/pages/asections/sectionHead.vue';
import sectionFooter from '@/pages/asections/sectionFooter.vue';
import optionsSection from '@/pages/asections/optionsSection';
import contactUs from '@/pages/blockchain/sections/contactUs.vue';
import time from '@/pages/blockchain/sections/time.vue';
import floatingSection from '@/pages/asections/floatingSection.vue';
import floatingElement from '@/pages/apartials/floatingElement.vue';
import { getAlternatesForCurrentLanguage, getCanonicals } from '@/helpers/locale-helper'

export default {
    metaInfo() {
        return {
            title: this.$t('meta_title-blockchain'),
            keywords: this.$t('meta_keywords'),
            description: this.$t('meta_blockchain-desctiption'),
            link: getAlternatesForCurrentLanguage().concat(getCanonicals())
        }
    },
    data: () => ({
        options: {
            description: {
                headtext: 'blockchain.desc.headtext',
                maintext: 'blockchain.desc.maintext',
                bottomtext: 'blockchain.desc.bottomtext',
                footertext: 'blockchain.desc.footertext'
            },

            icons: [{
                title: 'icontitle.options-fees',
                name: 'options-fees'
            },
            {
                title: 'icontitle.block-size',
                name: 'options-block-size'
            },
            {
                title: 'icontitle.reward',
                name: 'options-reward'
            },
            {
                title: 'icontitle.mining',
                name: 'options-mining'
            },
            {
                title: 'icontitle.staking',
                name: 'options-staking'
            },
            {
                title: 'icontitle.more',
                name: 'options-more'
            }],

            floatingBlocks: [{
                title: 'floating.title-1',
                icon: 'blockchain-support',
                listFirst: 'floating.blockchain.el-1.it-1',
                listSecond: 'floating.blockchain.el-1.it-2'
            },
            {
                title: 'floating.title-2',
                icon: 'blockchain-scalability',
                listFirst: 'floating.blockchain.el-2.it-1',
                listSecond: 'floating.blockchain.el-2.it-2'
            },
            {
                title: 'floating.title-3',
                icon: 'hardware-requirements',
                listFirst: 'floating.blockchain.el-3.it-1',
                listSecond: 'floating.blockchain.el-3.it-2'
            },
            {
                title: 'floating.title-4',
                icon: 'blockchain-tech-details',
                listFirst: 'floating.blockchain.el-4.it-1',
                listSecond: 'floating.blockchain.el-4.it-2'
            }]
        }
    }),
    components: {
        'section-head': sectionHead,
        'section-footer': sectionFooter,
        'section-options': optionsSection,
        'section-contact-us': contactUs,
        'section-time': time,
        'section-float': floatingSection,
        'element-float': floatingElement
    }
}
</script>

<style lang="sass" scoped>
@import '@/pages/blockchain/blockChainPage.sass'

.blockchain-page
    color: $color--dark
    
.floating-el 
    ::v-deep .floating-element_description-title_text
        margin: 0


.blockchain-vertical-pointer
    position: relative
    display: flex 
    align-items: flex-end 
    justify-content: center
    padding-top: 14px

    @media screen and (max-width: 768px), (min-device-width: 320px) and (max-device-width: 768px)
        display: none


    &-svg
        position: absolute   
        margin: 0 auto

        &-light
            position: absolute
            top: 0
            z-index: 1
</style>