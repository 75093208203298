<template>
    <section class="time-section" >
        <svg-icon name="borderTop"/>
        <v-container>
            <v-layout justify-center>
                <div class="flex xs12 sm12 md12 lg12 xl9">
                    <h2 class="title-main text-center">
                        {{ $t('time.title') }}
                    </h2>
                    <p class="subtitle-main text-center">{{$t('time.subtitle')}}</p>
                    <div class="time-section__in">
                        <ul class="time-section__list">
                            <li class="time-section__item" v-for="(item, idx) in list" :key="idx">
                                <div class="time-section__pic-block">
                                    <div class="time-section__pic-block-icon" v-if="item.title==='time.item-2'">
                                        <div class="info-tooltip">
                                            <svg-icon name="info-icon" />
                                            <span class="info-tooltiptext">
                                                {{$t('info-icon.tooltip')}}
                                            </span>
                                        </div>
                                    </div>
                                    <img class="item--img" :src="require(`@/assets/img/time/${item.icon}.svg`)" alt="">
                                    <h3 class="time-section__title subtitle-main" v-html="$t(item.title)"></h3>
                                    <div class="time-section__pic-block_border border-line-48"></div>
                                    <div class="time-section__pic-block_clock">
                                        <img class="clock--img" :src="require(`@/assets/img/time/time-clock.svg`)" alt="">
                                        <div class="time-section__pic-block_clock-count">{{$t(item.daysCount)}}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
export default {
        data() {
            return {
                list: [
                    {
                        'title': 'time.item-1',
                        'icon': 'time-1',
                        'daysCount': 'item.item-1-days'
                    },
                    {
                        'title': 'time.item-2',
                        'icon': 'time-2',
                        'daysCount': 'item.item-2-days'
                    },
                    {
                        'title': 'time.item-3',
                        'icon': 'time-3',
                        'daysCount': 'item.item-3-days'
                    },
                    {
                        'title': 'time.item-4',
                        'icon': 'time-4',
                        'daysCount': 'item.item-4-days'
                    },
                    {
                        'title': 'time.item-5',
                        'icon': 'time-5',
                        'daysCount': 'item.item-5-days'
                    },
                    {
                        'title': 'time.item-6',
                        'icon': 'time-6',
                        'daysCount': 'item.item-6-days'
                    },
                ]
            }
        }
    }
</script>
<style lang="sass">
@import '@/pages/blockchain/sections/time.sass'

.info-tooltip 
    .info-tooltiptext
        position: relative
        visibility: hidden
        width: 180px 
        background: #fff 
        border-radius: 10px
        color: $color--dark 
        text-align: center 
        bottom: -80px
        left: -80px
        position: absolute 
        filter: drop-shadow(0px 10px 30px rgba(4, 19, 59, 0.3))
        z-index: 10
        padding: 16px
        font-size: 16px 
        line-height: 18px
        font-weight: bold
        @media screen and (max-width: 768px), (min-device-width: 100px) and (max-device-width: 768px)
            bottom: 32px
            left: -168px



.info-tooltip
    position: relative 
    display: inline-block
    cursor: pointer
    &:hover 
        .info-tooltiptext
            visibility: visible

.svg-icon--tooltip-part
    width: 12px 
    height: 12px
    position: absolute
    bottom: -10px
    right: 10px
    transform: rotate(10deg)
    z-index: 9999

</style>